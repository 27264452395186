let nav = window.navigator;
let ua = window.navigator.userAgent;
let isSafariIOSDesktopMode = nav.platform && nav.platform === "MacIntel" && nav.maxTouchPoints && nav.maxTouchPoints > 1;

export let isMobileDevice = isSafariIOSDesktopMode || /Mobi|Tablet|Android|iPad|iPhone/.test(ua);

let isModernBrowser = window.Promise;

let isFirefoxWithPDFJS = !isMobileDevice && /irefox/.test(ua) && ua.split("rv:").length > 1 ? parseInt(ua.split("rv:")[1].split(".")[0], 10) > 18 : false;

let supportsPdfMimeType = nav.mimeTypes["application/pdf"];
let isIE = "ActiveXObject" in window;

let createAXO = function(type) {
  let ax;
  try {
    // eslint-disable-next-line no-undef
    ax = new ActiveXObject(type);
  } catch (e) {
    ax = null; // ensure ax remains null
  }
  return ax;
};

let supportsPdfActiveX = function() {
  return !!(createAXO("AcroPDF.PDF") || createAXO("PDF.PdfCtrl"));
};

export const isSafariDesktop = !isMobileDevice && nav.vendor && /Apple/.test(nav.vendor) && /Safari/.test(ua);

export const isIpone = /iPhone/.test(ua);

export const supportsPDF =
  // As of Sept 2020 no mobile browsers properly support PDF embeds
  (isIpone || !isMobileDevice) &&
  // We're moving into the age of MIME-less browsers. They mostly all support PDF rendering without plugins.
  (isModernBrowser ||
    // Modern versions of Firefox come bundled with PDFJS
    isFirefoxWithPDFJS ||
    // Browsers that still support the original MIME type check
    supportsPdfMimeType ||
    // Pity the poor souls still using IE
    (isIE && supportsPdfActiveX()));
