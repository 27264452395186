<template>
  <div class="pdfView">
    <div v-if="src || pdfUrl">
      <div>
        <div v-if="pdfUrl" class="iframe">
          <PdfView :pdfUrl="pdfUrl" />
        </div>
        <iframe v-else class="iframe" :src="src"></iframe>
      </div>
      <div class="btn">
        <van-button block type="primary" @click="back">返回合同管理</van-button>
      </div>
    </div>
    <div v-else style="text-align:center;margin:30px 0">
      <van-loading type="spinner" color="#1989fa" size="30px">合同文件加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { request } from "@/api/service";
import { request as requestExport } from "@/api/serviceExport";
import { isIpone, isMobileDevice } from "./supportsPDF";
import PdfView from "@/components/Pdf";
export default {
  name: "ProductContractDetailPdf",
  components: { PdfView },
  data() {
    return {
      pdfUrl: null,
      src: null
    };
  },
  async mounted() {
    const { id } = this.$route.query;
    if (!id) {
      this.$toast("请求参数有误");
      return false;
    }
    request({ url: `/afis-carservice/contract/downUrl/${id}`, method: "post" })
      .then(res => {
        if (isIpone || !isMobileDevice) {
          this.pdfUrl = res.replace("getFile", "preview");
          return false;
        }
        return requestExport({ url: res, method: "post" });
      })
      .then(file => {
        if (file === false) {
          return false;
        }
        let url = null;
        if (window.createObjectURL) {
          // basic
          url = window.createObjectURL(file);
        } else if (window.webkitURL) {
          // webkit or chrome
          url = window.webkitURL.createObjectURL(file);
        } else if (window.URL) {
          // mozilla(firefox)
          url = window.URL.createObjectURL(file);
        }
        this.src = `${window.location.origin}/pdf/web/viewer.html?file=${url}`;
      })
      .catch(error => {
        this.$toast(error.message);
      });
  },
  methods: {
    back() {
      this.$router.replace({
        path: "/product/contract/list"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.pdfView {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .iframe {
    width: 100%;
    height: calc(100vh - 65px);
    overflow: auto;
  }
  .btn {
    padding: 0 10px;
    font-size: 16px;
  }
}
</style>
